import { SignInFormTypes } from "@/@types/hookFormTypes";
import Container from "@/components/shared/Container";
import ErrorMessage from "@/components/shared/ErrorMessage";
import { Card } from "@/components/ui";
import Button from "@/components/ui/Button";
import { FormContainer, FormItem } from "@/components/ui/Form";
import Input from "@/components/ui/Input";
import { LABEL, MESSAGE, ROUTER } from "@/constants/commonConstant";
import { signInFormSchema } from "@/constants/hookFormSchema";
import {
  scannerRoleId,
  superAdminRoleId,
  userAdminRoleId,
  userEvaluatorRoleId,
  userRoleId,
} from "@/constants/roles.constant";
import Notification, { TOAST } from "@/new-services/notification";
import { useDynamicHookForm } from "@/utils/hooks/useFormHook";
import { getSession, signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
interface LoginProps {
  onLoginSuccess?: (success: boolean) => void;
}

const SignInForm = ({ onLoginSuccess }: LoginProps) => {
  const router = useRouter();

  const { register, handleSubmit, errors, isSubmitting } =
    useDynamicHookForm<SignInFormTypes>(signInFormSchema);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: SignInFormTypes) => {
    try {
      const { userName, password } = data;

      const loginDetails = await signIn("credentials", {
        redirect: false,
        userName,
        password,
      });
      if (loginDetails?.error) {
        return Notification({
          type: TOAST.ERROR,
          message: loginDetails.error,
        });
      }
      const session = await getSession();
      const userRole = session?.user?.userRole;
      switch (userRole) {
        case userEvaluatorRoleId:
        case scannerRoleId:
          if (onLoginSuccess) {
            onLoginSuccess(loginDetails?.ok ?? false);
          }
          break;
        case userAdminRoleId:
          Notification({
            type: TOAST.SUCCESS,
            message: MESSAGE.success,
          });
          router.push(`${ROUTER.examList}/${session?.user?.id}`);
          break;
        case userRoleId:
          Notification({
            type: TOAST.SUCCESS,
            message: MESSAGE.success,
          });
          router.push(`${ROUTER.examList}/${session?.user?.id}`);
          break;

        case superAdminRoleId:
          Notification({
            type: TOAST.SUCCESS,
            message: MESSAGE.success,
          });
          router.push(ROUTER.adminList);
          break;
        default:
          console.error("Login error:", userRole);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <>
      <Container className="flex flex-col flex-auto items-center justify-center min-w-0 h-full">
        <Card
          className="min-w-[320px] md:min-w-[465px] border-0 bg-themeGray rounded-lg shadow-none"
          bodyClass="md:pt-7 pb-8 px-10"
        >
          <p className="mb-8 text-sm xl:text-base">{LABEL.loginCredentials}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
              <FormItem
                label="Email/Phone No."
                className="mb-4"
                labelClass="!mb-1"
              >
                <Input
                  type="text"
                  autoComplete="off"
                  placeholder="Email/Phone No."
                  {...register("userName")}
                />
                <ErrorMessage
                  className="max-w-[350px] text-left"
                  message={errors?.userName?.message}
                />
              </FormItem>
              <FormItem label="Password" className="mb-9" labelClass="!mb-1">
                <div className="relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible className="h-5 w-5" />
                    ) : (
                      <AiOutlineEye className="h-5 w-5" />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="max-w-[350px] text-left"
                  message={errors?.password?.message}
                />
              </FormItem>
              <Button
                block
                loading={isSubmitting}
                variant="solid"
                type="submit"
                className="w-[160px] font-semibold uppercase"
              >
                {isSubmitting ? "Logging in..." : "Log In"}
              </Button>
            </FormContainer>
          </form>
        </Card>
      </Container>
    </>
  );
};

export default SignInForm;
