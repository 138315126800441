"use client";
import LoginStepper from "../component/LoginStep";
const LoginPage = () => {
  return (
    <>
      <LoginStepper />
    </>
  );
};

export default LoginPage;
