import { ErrorMessageTypes } from '@/@types/common'
import { FC } from 'react'

const ErrorMessage: FC<ErrorMessageTypes> = ({ className = '', message }) => {
    return (
        <div
            className={`text-xs text-red-600 font-medium mt-1.5  ${className}`}
        >
            {message}
        </div>
    )
}
export default ErrorMessage
