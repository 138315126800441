import { LinesAngle } from "@/components/Icons";
import { MESSAGE, ROUTER } from "@/constants/commonConstant";
import { userEvaluatorRoleId } from "@/constants/roles.constant";
import { faceVerification } from "@/new-services/actions/evaluator";
import Notification, { TOAST } from "@/new-services/notification";
import useResponse from "@/new-services/notification/useResponse";
import * as faceApi from "face-api.js";
import { getSession, useSession } from "next-auth/react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";

interface LoginProps {
  onLoginSuccess?: (success: boolean) => void;
}

const UserFaceVerification = ({ onLoginSuccess }: LoginProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [webcamConnected, setWebcamConnected] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false);
  const [detectingFace, setDetectingFace] = useState<boolean>(false);
  /*eslint-disable @typescript-eslint/no-explicit-any*/

  const [localStream, setLocalStream] = useState<any | null>();

  const router = useRouter();
  const { update } = useSession();

  const { handleResponse } = useResponse();
  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 194, height: 194, facingMode: "user" },
      });
      setLocalStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setWebcamConnected(true); // Webcam is successfully connected
      }
    } catch (err) {
      setWebcamConnected(false);
      setErrorMessage(
        "Webcam is not accessible. Please check your connection or permissions."
      );
      console.error("Error accessing webcam:", err);
    }
  };
  useEffect(() => {
    // Load face-api.js models
    const loadModels = async () => {
      try {
        await faceApi.nets.tinyFaceDetector.loadFromUri("/models");
        setIsModelLoaded(true);
        startVideo(); // Start video once models are loaded
      } catch (error) {
        setErrorMessage("Failed to load face detection models.");
        console.error("Error loading face-api.js models:", error);
      }
    };
    loadModels(); // Load models on component mount
  }, []);

  // Capture image from the video feed when face is detected
  const captureImage = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Convert canvas image to Blob
        canvas.toBlob((blob) => {
          if (blob) {
            setImageBlob(blob); // Store the captured image as a Blob
            handleFaceVerification(blob);
            stopWebCam();
          }
        }, "image/jpeg"); // Set the image type to JPEG
      }
    }
  };
  const stopWebCam = () => {
    localStream?.getTracks().forEach((track: any) => {
      track.stop();
    });
    setLocalStream(null);
    if (videoRef.current) {
      (videoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track: any) => {
          track.stop();
        });
    }
  };

  // Detect face using face-api.js
  const detectFace = async () => {
    if (!videoRef.current || !isModelLoaded) return;

    setDetectingFace(true); // Start detecting face
    const detectionInterval = setInterval(async () => {
      if (videoRef.current) {
        const detections = await faceApi.detectSingleFace(
          videoRef.current,
          new faceApi.TinyFaceDetectorOptions()
        );
        if (detections) {
          // If exactly one face is detected, capture the image
          clearInterval(detectionInterval); // Stop further detection
          captureImage();
          setDetectingFace(false); // Stop detecting face after capture
        }
      }
    }, 1000); // Check every 1 second
  };

  // Start face detection once video is connected
  useEffect(() => {
    if (webcamConnected && isModelLoaded && !imageBlob) {
      detectFace();
    }
  }, [webcamConnected, isModelLoaded, imageBlob]);

  const handleFaceVerification = async (blob: Blob) => {
    const file = new File([blob], "captured-image.jpeg", {
      type: "image/jpeg",
    });
    try {
      const response = await faceVerification(file);
      if (response.success) {
        if (response.data === true) {
          update({ isVerified: true });
          Notification({
            type: TOAST.SUCCESS,
            message: MESSAGE.success,
          });
          if (onLoginSuccess) {
            onLoginSuccess(response.data ?? false);
          }
          const session = await getSession();
          const userRole = session?.user?.userRole;
          if (userRole === userEvaluatorRoleId) {
            //Add evaluator redirection route
            router.push(ROUTER.evaluatorPapers);
          } else {
            //Add scanner redirection route
            router.push(`${ROUTER.scan}?scannerId=${session?.user?.id}`);
          }
        } else {
          Notification({
            message: MESSAGE.faceVerificationFailed,
            type: TOAST.ERROR,
          });
          setImageBlob(null);
          startVideo();
        }
      } else {
        handleResponse(response);
        setImageBlob(null);
        startVideo();
      }
    } catch (error) {
      router.push(ROUTER.login);
      Notification({
        message: MESSAGE.failure,
        type: TOAST.ERROR,
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-64">
      <p className="mb-8">Scan your face</p>
      <div className="relative w-56 min-h-44 p-4">
        <LinesAngle className="absolute top-0 left-0" />
        <LinesAngle className="absolute top-0 right-0 rotate-90" />
        <LinesAngle className="absolute left-0 bottom-0 -rotate-90" />
        <LinesAngle className="absolute right-0 bottom-0 rotate-180" />

        <div>
          {!webcamConnected && errorMessage ? (
            <p>{errorMessage}</p>
          ) : !imageBlob ? (
            <>
              <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{ width: "100%" }}
              />
              <p>
                {detectingFace ? "Detecting face..." : "Waiting for face..."}
              </p>
            </>
          ) : (
            <>
              <p>Image captured!</p>
              <Image
                width={194}
                height={194}
                src={URL.createObjectURL(imageBlob)}
                alt="Captured Image"
              />
            </>
          )}

          {/* Hidden canvas used for capturing the image */}
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>
      </div>
    </div>
  );
};

export default UserFaceVerification;
