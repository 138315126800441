import { useState } from "react";

import Container from "@/components/shared/Container";
import { Card, Steps } from "@/components/ui";
import FaceVerification from "./FaceVerification";
import LoginForm from "./SignInForm";

const steps = [
  {
    title: "Login",
    content: <LoginForm />,
  },
  {
    title: "Face Verification",
    content: <FaceVerification />,
  },
];

const LoginStepper = () => {
  const [current, setCurrent] = useState(0);
  const [showStep, setShowStep] = useState(false);

  const next = () => {
    setCurrent(current + 1);
  };

  const handleLoginSuccess = (success: boolean) => {
    if (success) {
      setShowStep(true);
      next();
    }
  };
  return (
    <>
      <Container className="flex flex-col flex-auto items-center justify-center min-w-0">
        {showStep && (
          <Steps
            current={current}
            className="min-w-[290px] md:min-w-[330px] mb-5 login-steps"
          >
            {steps.map((item, index) => (
              <Steps.Item key={index} title={item.title} />
            ))}
          </Steps>
        )}
      </Container>
      <div className="steps-content">
        {current === 0 ? (
          <LoginForm onLoginSuccess={handleLoginSuccess} />
        ) : (
          <Container className="flex flex-col flex-auto items-center justify-center min-w-0 h-full">
            <Card
              className="min-w-[320px] md:min-w-[465px] border-0 bg-themeGray rounded-lg shadow-none"
              bodyClass="md:pt-7 pb-8 px-10"
            >
              <FaceVerification onLoginSuccess={handleLoginSuccess} />
            </Card>
          </Container>
        )}
      </div>
    </>
  );
};

export default LoginStepper;
