"use client";

import { ROUTER } from "@/constants/commonConstant";
import { Http_Status } from "@/constants/httpStatus";
import { signOut } from "next-auth/react";
import { useEffect, useState } from "react";
import Notification, { TOAST } from ".";
import { TypeApiResponse } from "../types";

type TypeResponse = TypeApiResponse | undefined;

type TypeHandleResponse = {
  handleResponse: (args: TypeResponse) => void;
};

const useResponse = (): TypeHandleResponse => {
  const [response, setResponse] = useState<TypeResponse>();

  const handleResponse = (args: TypeResponse) => {
    setResponse(args);
  };

  useEffect(() => {
    if (response) {
      const { success, message, code } = response;

      if (message) {
        Notification({
          type: success ? TOAST.SUCCESS : TOAST.ERROR,
          message,
        });
      }

      if (code === Http_Status.UNAUTHORIZED) {
        signOut({ redirect: true, callbackUrl: ROUTER.login });
      }
    }
  }, [response]);

  return { handleResponse };
};
export default useResponse;
