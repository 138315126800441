import { API_NAME } from "@/services/config";

import { GET, POST, PUT_BY_ID } from "@/new-services/globalAPI";
import { userEvaluatorRoleId } from "@/constants/roles.constant";
import { TypeApiResponse } from "@/new-services/types";

export type TypeAddEvaluatorAction = {
  name: string;
  emailId: string;
  mobileNumber: string;
  password: string;
  questionId: string[];
  clientId: string;
};

export type TypeUploadUImage = {
  studentMasterId: string;
  file: File;
};

export type MultipleUploadUImage = {
  paperDetailsMasterId: string;
  studentMasterId: string;
  pageScreenshots: string[];
};

export type TypeFaceImage = {
  file: File;
};

/* ------------------------------------------------ Get paper evaluation data ------------------------------------------------ */
export const getPaperEvaluationData = async (
  questionPatternMasterId: string
): Promise<TypeApiResponse> => {
  return await GET(`${API_NAME.getPaperEvaluation}/${questionPatternMasterId}`);
};

/* ------------------------------------------------ Get paper evaluation remark data ------------------------------------------------ */
export const getPaperRemarkData = async (): Promise<TypeApiResponse> => {
  return await GET(`${API_NAME.paperRemark}`);
};

/* ------------------------------------------------ Face verification ------------------------------------------------ */
export const faceVerification = async (file: File) => {
  const uploadUrl = `${API_NAME.userVerify}`;
  const formData = new FormData();
  formData.append("UserImage", file);
  return await POST(uploadUrl, formData, true);
};

/* ------------------------------------------------ Save screen short ------------------------------------------------ */

export const captureScreenshotData = async ({
  studentMasterId,
  file,
}: TypeUploadUImage) => {
  const uploadUrl = `${API_NAME.saveScreenShot}/${studentMasterId}`;
  const formData = new FormData();
  formData.append("ScreenShotImage", file);

  return await POST(uploadUrl, formData, true);
};

/* ------------------------------------------------ Save page wise screen shot images------------------------------------------------ */
export const saveAnnotationScreenshotImages = async ({
  studentMasterId,
  pageScreenshots,
}: {
  studentMasterId: string;
  pageScreenshots: string[];
}): Promise<TypeApiResponse> => {
  const uploadUrl = `${API_NAME.saveAnnotationScreenshotImages}/${studentMasterId}`;
  const formData = new FormData();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageScreenshots.forEach((screenshotData: any) => {
    formData.append("AnnotatedImages", screenshotData.screenshot); // Also sending the page numbers
  });
  return await POST(uploadUrl, formData, true);
};

/* ------------------------------------------------ Save annotation ------------------------------------------------ */

export const saveAnnotationData = async ({
  payloadData,
}: {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  payloadData: any;
}): Promise<TypeApiResponse> => {
  return await POST(`${API_NAME.saveAnnotation}`, payloadData);
};

/* ------------------------------------------------ Get paper code ------------------------------------------------ */
export const getPaperCodeData = async (
  examMasterId: string
): Promise<TypeApiResponse> => {
  return await GET(`${API_NAME.getPaperCodeDropDownData}/${examMasterId}`);
};

/* ------------------------------------------------ Get paper code ------------------------------------------------ */
export const getQuestionDropDownData = async (
  paperDetailsMasterId: string
): Promise<TypeApiResponse> => {
  return await GET(
    `${API_NAME.getQuestionDropDownData}/${paperDetailsMasterId} `
  );
};

/* ------------------------------------------------ Get evaluator list ------------------------------------------------ */
export const getEvaluatorList = async (
  examMasterId: string,
  pageNo: number,
  pageSize: number
): Promise<TypeApiResponse> => {
  return await GET(
    `${API_NAME.evaluatorList}/${examMasterId}/${pageNo}/${pageSize}`
  );
};

/* ------------------------------------------------ Get evaluator data by id ------------------------------------------------ */
export const getEvaluatorDataById = async (
  userId: string | undefined
): Promise<TypeApiResponse> => {
  return await GET(`${API_NAME.getEvaluatorById}/${userId}`);
};

/* ------------------------------------------------ To add Evaluator------------------------------------------------ */

export const addEvaluator = async (data: TypeAddEvaluatorAction) => {
  return await POST(`${API_NAME.evaluatorCreate}/${userEvaluatorRoleId}`, data);
};

/* ------------------------------------------------ Get evaluator list ------------------------------------------------ */
export const getEvaluatorListPaperData = async (
  evaluatorId: string,
  pageNo: number,
  pageSize: number
): Promise<TypeApiResponse> => {
  return await GET(
    `${API_NAME.evaluatorAssignedPaper}/${evaluatorId}/${pageNo}/${pageSize}`
  );
};

/* ------------------------------------------------ To add Evaluator------------------------------------------------ */

export const editEvaluator = async (
  userId: string,
  data: TypeAddEvaluatorAction
) => {
  return await PUT_BY_ID(`${API_NAME.evaluatorEdit}`, userId, data);
};
